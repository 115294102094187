<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ scoreId ? 'Update': 'Add' }} local settings - {{score.name ? score.name : ''}}
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  Local settings
                </div>

              </div>
            </div>
          </div>
          <div class="card-body">
            <v-tabs vertical v-model="score_tab">
              <v-tab  href="#tab-1">
                General
              </v-tab>
              <v-tab  href="#tab-2">
                Exams
              </v-tab>
              <v-tab  href="#tab-4">
                Information update
              </v-tab>
              <v-tab  href="#tab-5">
                Private venues
              </v-tab>
              <v-tab  href="#tab-3">
                Payments
              </v-tab>
<!--              <v-tab  href="#tab-7">-->
<!--                eNews-->
<!--              </v-tab>-->
              <v-tab  href="#tab-6">
                Child safety
              </v-tab>

<!--              <v-tab  href="#tab-9">
                Cart
              </v-tab>-->
              <v-tab-item class="p-4" :value="'tab-1'">
                <v-row>
                  <v-col cols="12">
                    <h3>General Settings</h3> <hr/>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        v-model="score.name"
                        outlined  :error="$v.score.name.$error"
                        dense
                    >
                      <template v-slot:label>
                        Score <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.score.name.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.name" >*{{errors.name[0]}}</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="score.university_name"
                        outlined
                        dense>
                      <template v-slot:label>
                        Affiliated body <span class="text-danger"></span>
                      </template>
                    </v-text-field>

                  </v-col>
                  <v-col cols="4">
                    <v-file-input
                        v-model="score.image"
                        @change="previewImage"
                        placeholder="Select SCORE logo"
                        label="Upload logo"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        outlined
                        dense
                    >
                    </v-file-input>
                    <div v-if="imgurl" class="d-flex mt-3">
                      <v-img
                          v-if="imgurl != null"
                          :lazy-src="imgurl"
                          max-height="100"
                          max-width="100"
                          class="img-thumbnail"
                          :src="imgurl"></v-img>

                      <v-btn
                          elevation="3"
                          color="#dc3545"
                          @click="deleteScoreLogo(score.id)"
                          small
                          class="ml-3"
                      >
                        <v-icon color="#fff" class="font-13">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-text-field
                        label="Website"
                        type="url"
                        v-model="score.website"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Address"
                        v-model="score.address"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                        label="Notification Email"
                        v-model="score.notification_email"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Office Phone"
                        v-model="score.office_phone"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Event phone"
                        v-model="score.weekend_phone"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="4">
                    <v-text-field
                        label="Contact name"
                        v-model="score.contact_person"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Contact phone"
                        v-model="score.contact_person_phone"
                        outlined
                        dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Contact email"
                        v-model="score.contact_person_email"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-autocomplete
                        label="States"
                        item-text="name"
                        item-value="id"
                        :items="states"
                        v-model="score.state_ids"
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="allSelectStates"
                        >
                          <v-list-item-action>
                            <v-icon :color="score.state_ids > 0 ? 'indigo darken-4' : ''">
                              {{ iconState }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select all
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-1"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="score.country_id"
                              :items="countries"
                              outlined
                              item-text="name"
                              item-value="id"
                              dense>
                      <template v-slot:label>
                        Country <span class="text-danger">*</span>
                      </template>
                    </v-autocomplete>

                    <span class="text-danger" v-if="errors.country_id" >*{{errors.country_id[0]}}</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                        label="Time zone"
                        v-model="score.time_zone"
                        outlined
                        dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-file-input
                        v-model="score.map"
                        @change="previewMap"
                        placeholder="Map(.jpg or .png only)"
                        label="Map upload"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        outlined
                        dense
                    >
                    </v-file-input>
                    <span class="text-danger" v-if="errors.map" >{{errors.map[0]}}</span>

                    <div v-if="mapUrl" class="text-center d-flex mt-3">
                      <v-img
                          v-if="mapUrl != null"
                          :lazy-src="imgurl"
                          max-height="100"
                          max-width="100"
                          class="img-thumbnail"
                          :src="mapUrl"></v-img>

                          <v-btn
                            elevation="3"
                            color="#dc3545"
                            @click="deleteMap(score.id)"
                            small
                            class="ml-3"
                          >
                            <v-icon color="#fff" class="font-13">
                                mdi-delete
                            </v-icon>
                          </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="6" md="4">
                    Australian state
                    <v-switch
                        v-model="score.is_domestic"
                        :label="score.is_domestic ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Email notification to state email address?
                    <v-switch
                        v-model="score.enable_notifications"
                        :label="score.enable_notifications ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-file-input
                        v-model="score.certificate_signature_image"
                        @change="previewcertificateSignatureImage"
                        placeholder="Certificate signature image"
                        label="Certificate signature image"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        outlined
                        dense
                    >
                    </v-file-input>
                    <div v-if="certificateSignatureImageUrl" class="d-flex mt-3">
                      <v-img
                          v-if="certificateSignatureImageUrl != null"
                          :lazy-src="certificateSignatureImageUrl"
                          max-height="100"
                          max-width="100"
                          class="img-thumbnail"
                          :src="certificateSignatureImageUrl"></v-img>

                      <v-btn
                          elevation="3"
                          color="#dc3545"
                          @click="deleteCertificateSignatureImage(score.id)"
                          small
                          class="ml-3"
                      >
                        <v-icon color="#fff" class="font-13">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="p-4" :value="'tab-2'">
                <v-row>
                  <v-col cols="12">
                    <h3>Exam Settings</h3> <hr/>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-autocomplete
                        label="Year"
                        v-model="score.current_enrolment_year_id"
                        :items="enrollments"
                        outlined
                        item-text="year"
                        item-value="id"
                        dense>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                    <h5>Enrolment form</h5>
                  </v-col>
                  <v-col cols="4" md="4">
                    Practical exams
                    <v-switch
                        v-model="score.enable_practical_exams"
                        :label="score.enable_practical_exams ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="4" md="4">
                    Online exams
                    <v-switch
                        v-model="score.enable_online_exams"
                        :label="score.enable_online_exams ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="4" md="4">
                    Online courses
                    <v-switch
                        v-model="score.enable_online_courses"
                        :label="score.enable_online_courses ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="4" md="4">
                    AMEB awards
                    <v-switch
                        v-model="score.enable_ameb_awards"
                        :label="score.enable_ameb_awards ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="4" md="4">
                    Rockschool theory exams
                    <v-switch
                        v-model="score.enable_rockschool_theory_exams"
                        :label="score.enable_rockschool_theory_exams ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="4">
                    Gender identity
                    <v-switch
                        v-model="score.gender_identity"
                        :label="score.gender_identity ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4" v-if="score.gender_identity">
                    Preferred pronouns
                    <v-switch
                        v-model="score.preferred_pronoun_enabled"
                        :label="score.preferred_pronoun_enabled ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Ask school name on enrolment form
                    <v-switch
                        v-model="score.require_school_name_on_enrolment_form"
                        :label="score.require_school_name_on_enrolment_form ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="4">
                    Drum selection
                    <v-switch
                        v-model="score.requires_drum_selection"
                        :label="score.requires_drum_selection ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Guitar selection
                    <v-switch
                        v-model="score.requires_guitar_selection"
                        :label="score.requires_guitar_selection ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Ask for unsuitable examiners
                    <v-switch
                        v-model="score.enable_unsuitable_examiners"
                        :label="score.enable_unsuitable_examiners ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="4">
                    Parent details requested

                    <v-switch
                        v-model="score.enable_enrolment_parent_information"
                        :label="score.enable_enrolment_parent_information ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Parent details mandatory
                    <v-switch v-model="score.required_parent_information_on_enrolment"
                        :label="score.required_parent_information_on_enrolment ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3">
                    </v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Teacher details requested
                    <v-switch v-model="score.enable_enrolment_teacher_information"
                        :label="score.enable_enrolment_teacher_information ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3">
                    </v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Teacher details mandatory
                    <v-switch
                        v-model="score.required_teacher_information_on_enrolment"
                        :label="score.required_teacher_information_on_enrolment ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Nominate for prize on report
                    <v-switch
                        v-model="score.enable_nominate_for_prize_on_report"
                        :label="score.enable_nominate_for_prize_on_report ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <h3>SCORE Settings</h3> <hr/>
                  </v-col>
                  <v-col cols="6" md="4">
                    Enrolment form active
                    <v-switch
                        v-model="score.enable_enrolment_form"
                        :label="score.enable_enrolment_form ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    SCORE repertoire form
                    <v-switch
                        v-model="score.enable_repertoire_playlist"
                        :label="score.enable_repertoire_playlist ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-autocomplete label=" Official doc naming convention" v-model="score.naming_order" :items="naming_orders" item-text="text" item-value="value" dense outlined>
                      <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" md="4">
                    Publish examiner on notification
                    <v-switch
                        v-model="score.enable_examiner_name_on_notification_letter"
                        :label="score.enable_examiner_name_on_notification_letter ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Print examiner on certificate
                    <v-switch
                        v-model="score.enable_examiner_name_on_certificate"
                        :label="score.enable_examiner_name_on_certificate ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="4">
                    Print teacher on certificate
                    <v-switch
                        v-model="score.show_teacher_name_in_certificate"
                        :label="score.show_teacher_name_in_certificate ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>





                </v-row>
              </v-tab-item>
              <v-tab-item class="p-4" :value="'tab-3'">
                <v-row>
                  <v-col cols="12">
                    <h3>Payment settings</h3>
                    <hr/>
                  </v-col>
                  <v-col cols="12">
                    <h5>Transaction settings</h5>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="score.purchase_prefix"
                        outlined :error="$v.score.purchase_prefix.$error"
                        dense>
                      <template v-slot:label>
                        Purchase prefix <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div class="text-danger"
                        v-if="$v.score.purchase_prefix.$error">
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.purchase_prefix" >*{{errors.purchase_prefix[0]}}</span>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        v-model="score.tax_setting"
                        outlined :error="$v.score.tax_setting.$error"
                        dense
                    >
                      <template v-slot:label>
                        Tax setting <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.score.tax_setting.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.tax_setting" >*{{errors.tax_setting[0]}}</span>
                  </v-col>
                  <v-col cols="6">
                    <v-select :items="currencies"  :menu-props="{ bottom: true, offsetY: true }"  label="Currency" item-text="text" item-value="value" v-model="score.currency" outlined dense></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="score.currency_symbol"
                        outlined :error="$v.score.currency_symbol.$error" dense>
                      <template v-slot:label>
                        Currency symbol <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <div
                        class="text-danger"
                        v-if="$v.score.currency_symbol.$error"
                    >
                      This information is required
                    </div>
                    <span class="text-danger" v-if="errors.currency_symbol" >*{{errors.currency_symbol[0]}}</span>
                  </v-col>
                  <v-col cols="12">
                    <h5>Checkout settings</h5>
                  </v-col>
                  <v-col cols="6" md="12">
                    Credit Card
                    <v-switch
                        v-model="score.enable_credit_card"
                        :label="score.enable_credit_card ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="12">
                    Paypal
                    <v-switch
                        v-model="score.enable_paypal"
                        :label="score.enable_paypal ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="12" v-if="score.enable_paypal">
                    <v-select :items="paypal_account_types" :menu-props="{ bottom: true, offsetY: true }"
                              label="Paypal account configuration" item-text="text" item-value="value"
                              v-model="score.paypal_account_type"
                              outlined
                              dense
                    >
                    </v-select>
                    <span class="text-danger" v-if="$v.score.paypal_account_type" >Paypal account configuration</span>
                  </v-col>
                  <v-col cols="6" md="12">
                    Alepay
                    <v-switch
                        v-model="score.enable_alepay"
                        :label="score.enable_alepay ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="12">
                    SchoolPay
                    <v-switch
                        v-model="score.enable_school_pay"
                        :label="score.enable_school_pay ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="12">
                    Display checkout acknowledgement
                    <v-switch
                        v-model="score.show_cart_acknowledgement"
                        :label="score.show_cart_acknowledgement ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="p-4" :value="'tab-4'">
                <v-row>

                  <v-col cols="12">
                    <h3>Information update request</h3>
                    <hr/>
                  </v-col>
                  <v-col cols="6" md="4">
                    Enroller update approval
                    <v-switch
                        v-model="score.enable_account_holder_update_approval"
                        :label="score.enable_account_holder_update_approval ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Enable candidate information update request through state approval
                    <v-switch
                        v-model="score.enable_candidate_update_approval"
                        :label="score.enable_candidate_update_approval ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="4">
                    Notify state on candidate attachment submission
                    <v-switch
                        v-model="score.notify_on_candidate_attachment"
                        :label="score.notify_on_candidate_attachment ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="p-4" :value="'tab-5'">
                <v-row>
                  <v-col cols="6" md="4">
                    Session includes private venues
                    <v-switch
                        v-model="score.enable_private_venues"
                        :label="score.enable_private_venues ? 'Yes' : 'No'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>

                  <v-col cols="6" md="4">
                    Ask for confirmation of venue requirement minimum criteria met (on venue signup)
                    <v-switch
                        v-model="score.enable_venue_requirement"
                        :label="score.enable_venue_requirement ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                    <span
                        class="text-danger"
                        v-if="$v.score.enable_venue_requirement.$error">
                    This information is required
                  </span>
                  </v-col>

                </v-row>
              </v-tab-item>
              <v-tab-item class="p-4" :value="'tab-6'">
                <v-row>
                  <v-col cols="6" md="4">
                    Enable children safety check?
                    <v-switch
                        v-model="score.enable_children_check"
                        :label="score.enable_children_check ? 'Enabled' : 'Disabled'"
                        color="indigo darken-3"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-tab-item>

<!--              <v-tab-item class="p-4" :value="'tab-7'">-->
<!--                <v-row>-->
<!--                  <v-col cols="6" md="4">-->
<!--                    E-news-->
<!--                    <v-switch-->
<!--                        v-model="score.enable_newsletter_subscription"-->
<!--                        :label="score.enable_newsletter_subscription ? 'Enabled' : 'Disabled'"-->
<!--                        color="indigo darken-3"-->
<!--                    ></v-switch>-->
<!--                  </v-col>-->
<!--                  <v-col cols="6" md="12" v-if="score.enable_newsletter_subscription">-->
<!--                    <v-text-field label="Mailchimp API key"-->
<!--                                  v-model="score.mailchimp_api_key"-->
<!--                                  outlined :error="$v.score.mailchimp_api_key.$error"-->
<!--                                  dense>-->
<!--                    </v-text-field>-->
<!--                    <span class="text-danger" v-if="$v.score.mailchimp_api_key.$error" >This information is required</span>-->

<!--                  </v-col>-->
<!--                  <v-col cols="6" md="12" v-if="score.enable_newsletter_subscription">-->
<!--                    <v-text-field label="Mailchimp list ID"-->
<!--                                  v-model="score.mailchimp_list_id"-->
<!--                                  outlined :error="$v.score.mailchimp_list_id.$error"-->
<!--                                  dense>-->
<!--                    </v-text-field>-->
<!--                    <span class="text-danger" v-if="$v.score.mailchimp_list_id.$error" >This information is required</span>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-tab-item>-->

            </v-tabs>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn
                      text
                      @click="back">
                    Cancel
                  </v-btn>
                  <v-btn
                      dark
                      x-large
                      @click="createOrUpdate()"
                      :loading="loading"
                  >
                    Save
                  </v-btn>

                </v-col>
              </v-row>

          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required,requiredIf} from "vuelidate/lib/validators";
import CountryService from "@/services/country/CountryService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import StateService from "@/services/setting/state/StateService";
import ScoreService from "@/services/score/score/ScoreService";
import SettingService from "@/services/setting/SettingService";
const settingService=new SettingService();

const country = new CountryService();
const state = new StateService();
const enrollment = new EnrolmentYearService();
const score = new ScoreService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      score_tab:'tab-1',
      loading:false,
      edit: false,
      errors: [],
      states: [],
      countries: [],
      enrollments: [],
      imgurl: null,
      mapUrl: null,
      certificateSignatureImageUrl: null,
      currencies:[

      ],
      score: {
        name: '',
        university_name: '',
        state_ids: [],
        country_id:14,
        purchase_prefix: '',
        enable_online_exams: true,
        enable_online_courses: true,
        enable_practical_exams: true,
        enable_ameb_awards: true,
        enable_rockschool_theory_exams:false,
        currency: '',
        currency_symbol: '',
        address: '',
        website: '',
        office_phone: '',
        weekend_phone: '',
        enable_notifications: true,
        notification_email: '',
        contact_person: '',
        contact_person_email: '',
        contact_person_phone: '',
        enable_paypal: true,
        paypal_account_type: '',
        enable_credit_card: true,
        enable_alepay: true,
        enable_school_pay: true,
        enable_enrolment_teacher_information: true,
        tax_setting: '',
        time_zone: '',
        enable_unsuitable_examiners: true,
        enable_repertoire_playlist: true,
        enable_private_venues: false,
        enable_enrolment_form: true,
        enable_examiner_name_on_certificate: true,
        enable_examiner_name_on_notification_letter: true,
        current_enrolment_year_id: '',
        enable_children_check: true,
        image: null,
        map: null,
        score_default_logo: '',
        certificate_signature_image: '',
        enable_account_holder_update_approval: true,
        enable_candidate_update_approval: true,
        show_cart_acknowledgement: true,
        is_domestic: true,
        gender_identity: true,
        show_teacher_name_in_certificate: false,
        preferred_pronoun_enabled:false,
        enable_newsletter_subscription:false,
        notify_on_candidate_attachment: false,
        mailchimp_list_id:'',
        mailchimp_api_key:'',
        enable_venue_requirement: true,
        require_school_name_on_enrolment_form:false,
        naming_order:'first_name_to_last_name',
        required_teacher_information_on_enrolment:false,
        enable_enrolment_parent_information:true,
        required_parent_information_on_enrolment:true,
        requires_guitar_selection:false,
        requires_drum_selection:false,
        enable_nominate_for_prize_on_report: true,
      },
      paypal_account_types:[],
      naming_orders:[
        {value:'first_name_to_last_name' ,text:'First name & then surname'},
        {value:'last_name_to_first_name' ,text:'Surname first & then first name'},
      ],
    }
  },
  validations: {
    score: {
      name: { required },
      purchase_prefix: { required },
      currency_symbol: { required },
      tax_setting: { required },
      paypal_account_type: {
        required: requiredIf(function (nestedModel) {
          return this.score.enable_paypal;
        })
      },
      mailchimp_list_id: {
        required: requiredIf(function (nestedModel) {
          return this.score.enable_newsletter_subscription;
        })
      },
      mailchimp_api_key: {
        required: requiredIf(function (nestedModel) {
          return this.score.enable_newsletter_subscription;
        })
      },
      enable_venue_requirement:{}
    }
  },
  mounted() {
    this.getAllCountries();
    this.getAllStates();
    this.getAllEnrollments();
    this.getCurrency();
    this.getPaypalAccountType();
    this.scoreDetail();
  },
  computed: {
    allSelectState () {
      return this.score.state_ids.length === this.states.length
    },
    selectState () {
      return this.score.state_ids.length > 0 && !this.allSelectState
    },
    iconState () {
      if (this.allSelectState) return 'mdi-close-box'
      if (this.selectState) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    routeTab(){
      if(this.score){
        let title = this.score.name
        return {
          title: title
        }
      }
    },
    scoreId() {
      return this.$route.params.scoreId;
    },
  },
  methods: {
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.score) {
        if (key === "image" && this.score[key] != null && this.score[key] != undefined) {
          formData.append('image', this.score[key]);
        }
        else if (key === "map" && this.score[key] != null && this.score[key] != undefined) {
          formData.append('map', this.score[key]);
        }
        else {
          if(this.score[key]){
            formData.append(key,this.score[key]);
          }
        }
      }
      return formData;
    },
    scoreDetail() {

      if (this.scoreId)
      {
        score
            .show(this.scoreId)
            .then(response => {
              this.score = response.data.score;
              if(response.data.status=='Unauthorized'){

                this.$router.push({
                  name: 'unauthorized',
                });
                this.$tabs.refresh()
              }
              this.imgurl = this.score.score_default_logo_path ? this.score.score_default_logo_path.thumb : null;
              this.certificateSignatureImageUrl = this.score.certificate_signature_image_path ? this.score.certificate_signature_image_path.thumb : null;
              this.mapUrl = this.score.map_path ? this.score.map_path.thumb : null;

              if(this.score.state_ids == null) {
                this.score.state_ids = []
              }
            })
      }


    },
    back(){
      this.$tabs.close();
    },
    previewImage(){
      if(this.score.image != null){
        this.imgurl = URL.createObjectURL(this.score.image)
      } else {
        this.imgurl = null;
      }
    },
    previewMap(){
      if(this.score.map != null){
        this.mapUrl = URL.createObjectURL(this.score.map)
      } else {
        this.mapUrl = null;
      }
    },
    previewcertificateSignatureImage(){
      if(this.score.certificate_signature_image != null){
        this.certificateSignatureImageUrl = URL.createObjectURL(this.score.certificate_signature_image)
      } else {
        this.certificateSignatureImageUrl = null;
      }
    },
    getAllCountries() {
      country.getAllCountry().then(response => {
        this.countries = response.data.data;
      });
    },
    getAllStates() {
      state.all().then(response => {
        this.states = response.data.data;
      });
    },
    getAllEnrollments() {
        enrollment.all().then(response => {
        this.enrollments = response.data;
      });
    },
    allSelectStates () {
      this.$nextTick(() => {
        if (this.allSelectState) {
          this.score.state_ids = []
        } else {
          this.score.state_ids = [];
          this.states.forEach(state => {
            this.score.state_ids.push(state.id);
          });
        }
      })
    },
    goToValidationStep(){
      if(this.$v.$error) {
        if (this.$v.score.name.$error) {
          this.score_tab = 'tab-1';
        }else if(this.$v.score.purchase_prefix.$error || this.$v.score.currency_symbol.$error){
          this.score_tab = 'tab-3';
        }else if(this.$v.score.mailchimp_api_key.$error || this.$v.score.mailchimp_list_id.$error){
          this.score_tab = 'tab-8';
        }
      }
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        this.goToValidationStep();
        this.$snotify.error("Required field missing");
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.scoreId == null || this.scoreId == undefined) this._create();
        else this.update();
      }
    },
    _create: function(){
      this.loading=true;
      let formData = this.convertToFormData();
      score
          .create(formData)
          .then(response =>{
            this.$snotify.success("Score added");
            this.back();
            this.loading=false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update(){
      this.loading=true;
      let formData = this.convertToFormData();
      score
          .update(this.scoreId, formData)
          .then(response =>{
            this.$snotify.success("Score updated");
            this.scoreDetail();
            this.loading=false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.loading=false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getCurrency(){
      settingService
      .getCurrencyData()
      .then(response=>{
        for(let key in response.data.currencies){
          this.currencies.push({value:key, text:response.data.currencies[key]})
        }
      })
    },
    getPaypalAccountType(){
      settingService
      .getPaypalAccountType()
      .then(response=>{
        for(let key in response.data.paypal_account_type){
          this.paypal_account_types.push({value:key, text:response.data.paypal_account_type[key]})
        }
      })
    },
    deleteScoreLogo(scoreId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            score
            .deleteScoreLogo(scoreId)
            .then((res) => {
              this.$snotify.success('Score logo deleted');
              this.scoreDetail();
              this.imgurl = null;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
          }
        }
      });
    },
    deleteCertificateSignatureImage(scoreId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            score
            .deleteCertificateSignatureImage(scoreId)
            .then((res) => {
              this.$snotify.success('Digital certificate signature image deleted');
              this.scoreDetail();
              this.imgurl = null;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
          }
        }
      });
    },
    deleteMap(scoreId){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            score
            .deleteMap(scoreId)
            .then((res) => {
              this.$snotify.success('Map image deleted');
              this.scoreDetail();
              this.imgurl = null;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>